import React, { useContext, useEffect, useState } from "react";
// import "./BannerForm.css";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { HomeContext } from "../../App";
import useQuery from "../../utils/useQuery";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import API from "../../service/Api";
import Icon from "@mdi/react";
import { mdiCash } from "@mdi/js";
import { mdiAt } from "@mdi/js";
import { mdiPlay } from "@mdi/js";

const BannerForm = (props) => {
  const { GaTrack } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isHover, setIsHover] = useState(false);
  const [focusedField, setFocusedField] = useState(false);
  const [fieldIsHover, setFieldIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const data = useContext(HomeContext);

  const bannerSectionContent = data.customization.bannerSection.content;
  const bannerSectionSty = data.customization.bannerSection.style;

  const ctaparaColor = {
    color: bannerSectionSty.termsTextColor,
  };
  const ctalinkColor = {
    color: bannerSectionSty.themeColor2,
  };
  const iconColor = {
    color: "#fff",
  };
  const handleFieldMouseEnter = (field) => {
    //alert("Test");
    setFieldIsHover(field);
  };
  const handleFieldMouseLeave = (field) => {
    setFieldIsHover(null);
    // setFocusedField(false);
  };

  const handleFocus = (field) => {
    setFocusedField(field);
  };
  const handleBlur = () => {
    setFocusedField(false);
  };

  const inputBg = {
    backgroundColor: bannerSectionSty.fieldBg,
  };
  const disc_link_color = {
    color: bannerSectionSty.discLinkColor,
  };
  const input_focus = {
    color:
      bannerSectionSty && bannerSectionSty.inputfocuscolor
        ? bannerSectionSty.inputfocuscolor === undefined
          ? bannerSectionSty.fieldHoverBorder
          : bannerSectionSty.inputfocuscolor
        : bannerSectionSty.fieldHoverBorder,
  };

  const inputFocus = {
    // borderColor: bannerSectionSty.fieldHoverBorder,
    // margin: "-2px",
    border: "1px solid",
    WebkitBoxShadow: focusedField
      ? `2px 2px 2px 0px ${input_focus?.color}, -2px -2px 2px 0px ${input_focus?.color}, -2px 2px 2px 0px ${input_focus?.color}, 2px -2px 2px 0px ${input_focus?.color}`
      : "none",
    borderColor:
      fieldIsHover || focusedField
        ? bannerSectionSty.fieldHoverBorder
        : bannerSectionSty.fieldBorderColor,
  };

  const ctaStyle = {
    backgroundColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    color: isHover
      ? bannerSectionSty.ctaHoverTextColor
      : bannerSectionSty.ctaTextColor,
    border: "0px solid",
    borderTopColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    borderLeftColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    borderRightColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    borderBottomColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
  };

  const maxAmpunt = data.max_amount;

  // pass query params

  const query = useQuery();
  const navigate = useNavigate();

  const uid = query.get("utm_source") || "";
  const utm_campaign = query.get("utm_campaign") || "";
  const utm_medium = query.get("utm_medium") || "";
  const s2 = query.get("s2") || "";
  const s3 = query.get("s3") || "";
  const max_amount = query.get("max_amount") || "";
  const email = query.get("email") || "";

  const onSubmit = (data) => {
    const myData = {
      ...data,
      uid,
      utm_campaign,
      utm_medium,
      s2,
      s3,
      max_amount,
    };
    // setformData(myData);

    const params = {
      email: myData.email || "",
      firstName: myData.firstName || "",
      lastName: myData.lastName || "",
      s1: myData.utm_campaign || "",
      s2: myData.s2 || "",
      s3: myData.s3 || "",
      uid: myData.uid || "",
      utm_medium: myData.utm_medium || "",
      amount: myData.amount || "",
      max_amount: myData.max_amount || "",
    };

    navigate({
      pathname: "/form",
      search: `?${createSearchParams(params)}`,
    });

    GaTrack("First Form Submit", "First Form Submit");
  };

  // Modal
  const initModelData = { name: "", title: "", children: "" };

  const [flag, setFlag] = useState(false);
  const [model, setModel] = useState(initModelData);
  const [apiModel, setApiModel] = useState([]);
  useEffect(() => {
    fetch("data/website_config.json")
      .then((res) => res.json())
      .then((data) => {
        // setWebname(data);
        handleApiCall(data.WEBSITE_NAME, data.WEBSITE_ID);
      });
  }, []);

  const handleApiCall = async (web, websiteId) => {
    try {
      const article = { websiteName: web, webSiteId: websiteId };

      let res = await API.post("Content/getWebsiteContent", article);

      setApiModel(res.data[0]);
    } catch (error) {
      console.error("whySectionerror", error.message);
    }
  };

  const setShow = (arg) => {
    const x = JSON.parse(apiModel[arg] ? apiModel[arg] : {});

    setModel({ name: "", title: x.heading, children: x.content.text });
    setFlag(true);
  };

  // Animation Dot fun
  const [fillColor, setFillColor] = useState("#ff4f81"); // Set your initial fill color here

  const handleColorChange = () => {
    // Update fill color dynamically, you can replace this logic with your own color change logic
    const newColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    setFillColor("green");
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="bannerForm">
        <label className="pure-material-textfield-filled">
          <input
            name="amount"
            className="amount_but inline-edit"
            placeholder="$1000"
            type="tel"
            id="amount"
            onFocus={() => handleFocus("amount")}
            style={
              focusedField === "amount"
                ? inputFocus
                : fieldIsHover === "amount"
                ? inputFocus
                : inputBg
            }
            onMouseEnter={() => handleFieldMouseEnter("amount")}
            onMouseLeave={handleFieldMouseLeave}
            {...register("amount", {
              required: "This input is required.",
              pattern: {
                value: /\b(0|[1-9]\d*)?([05]0)\b/,
                message: "This value should be a multiple of 50.",
              },
              min: {
                value: 100,
                message: `This value should be between 100 and ${maxAmpunt}.`,
              },
              max: {
                value: maxAmpunt,
                message: `This value should be between 100 and ${maxAmpunt}.`,
              },
            })}
            onBlur={handleBlur}
          />
          <span
            style={{
              color: focusedField ? bannerSectionSty.themeColor2 : "#858585",
            }}
          >
            {bannerSectionContent.label1}
          </span>
          <Icon
            style={{
              color: fieldIsHover ? bannerSectionSty.themeColor2 : "#858585",
            }}
            path={mdiCash}
            size={1.2}
          />
          <ErrorMessage
            errors={errors}
            name="amount"
            render={({ message }) => <p className="error_color">{message}</p>}
          />
          <p
            style={{ display: !errors.amount ? "block" : "none" }}
            className="amount_hint"
          >{`${bannerSectionContent.AmountHint} $${maxAmpunt}.`}</p>
        </label>

        <label className="pure-material-textfield-filled">
          <input
            type="email"
            className="amount_but inline-edit"
            id="email"
            name="email"
            placeholder="name@example.com"
            onFocus={() => handleFocus("email")}
            onBlur={handleBlur}
            style={
              focusedField === "email"
                ? inputFocus
                : fieldIsHover === "email"
                ? inputFocus
                : inputBg
            }
            onMouseEnter={() => handleFieldMouseEnter("email")}
            onMouseLeave={handleFieldMouseLeave}
            {...register("email", {
              required: "Email Address is required",

              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            })}
            value={email !== "" ? email : undefined}
          />
          <span
            style={{
              color: focusedField ? bannerSectionSty.themeColor2 : "#858585",
            }}
          >
            {bannerSectionContent.label2}
          </span>
          <Icon
            style={{
              color: fieldIsHover ? bannerSectionSty.themeColor2 : "#858585",
            }}
            path={mdiAt}
            size={1.1}
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => <p className="error_color">{message}</p>}
          />
        </label>

        <button
          type="submit"
          className="form_submit"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={ctaStyle}
        >
          <span className="submit_underline">
            {bannerSectionContent.ctaText}
          </span>{" "}
          -{" "}
          <span className="submit_blur">
            {bannerSectionContent.ctaSubText}{" "}
          </span>{" "}
          <Icon path={mdiPlay} size={1} />
        </button>
        <p className="form_concent" style={ctaparaColor}>
          By clicking '{bannerSectionContent.ctaText}', you agree to our &nbsp;{" "}
          <Link to="/privacy-policy" target="_blank">
            <span style={disc_link_color}> Privacy Policy</span>
          </Link>
          ,{" "}
          <Link to="/terms-of-use" target="_blank">
            <span style={disc_link_color}>Terms</span>
          </Link>
          , &nbsp;{" "}
          <Link to="/e-consent" target="_blank">
            <span style={disc_link_color}>E-Consent</span>
          </Link>
          ,{" "}
          <Link to="/rate-and-fees" target="_blank">
            <span style={disc_link_color}>Rate & Fees</span>
          </Link>{" "}
          and receive special offers from us and our marketing partners via
          email communication.
        </p>
      </form>
    </>
  );
};

export default BannerForm;
